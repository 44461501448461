<template>
  <div class="px-5">
    <validation-observer ref="simpleRules">
      <b-card>
        <validation-provider
          #default="{ errors }"
          name="file"
          rules="required"
        >
          <label>File</label>
          <b-form-file
            v-model="file"
            :placeholder="$t('messages.file-upload-info')"
            :drop-placeholder="$t('messages.file-drop-info')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <b-col
          v-if="isProcessing && $store.state.app.fileUploadProgress < 100"
          md="12"
          class="pt-2"
        >
          <b-progress
            :max="100"
          >
            <b-progress-bar :value="$store.state.app.fileUploadProgress">
              <strong>{{ $store.state.app.fileUploadProgress.toFixed(2) }} %</strong>
            </b-progress-bar>
          </b-progress>
        </b-col>
        <b-row class="my-2">
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="version"
              rules="required"
            >
              <label>{{ $t('Version') }}</label>
              <b-form-input
                v-model="version"
                :placeholder="$t('Version')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <label>{{ $t('Platform') }}</label>
            <validation-provider
              #default="{ errors }"
              name="platform"
              rules="required"
            >
              <b-form-select
                v-model="platform"
                :placeholder="$t('Platform')"
                :options="platformList"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <label>{{ $t('Architecture') }}</label>
            <validation-provider
              #default="{ errors }"
              name="architecture"
              rules="required"
            >
              <b-form-select
                v-model="architecture"
                :placeholder="$t('Architecture')"
                :options="architectureList"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <label>{{ $t('Description') }}</label>
        <quill-editor
          v-model="description"
        />
        <!-- <b-form-textarea
          v-model="description"
          placeholder="App description"
        /> -->
        <b-form-checkbox
          switch
          class="mt-1"
        >
          {{ $t('Force Update') }}
        </b-form-checkbox>
        <b-button
          class="mt-1 float-right"
          variant="primary"
          :disabled="isProcessing"
          @click="uploadFile"
        >
          <b-spinner
            v-if="isProcessing"
            small
          />
          <feather-icon icon="PlusIcon" />  {{ $t('Upload') }}
        </b-button>
      </b-card>
    </validation-observer>
    <b-tabs pills>
      <b-tab
        v-for="list of platformList"
        :key="list.value"
        :title="list.text"
        lazy
      >
        <list-files
          :key="updateKey"
          :platform="list.value"
          :architecture-list="architectureList"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BFormFile, BCard, BButton, BFormInput, BFormSelect, BTab, BTabs, BFormCheckbox, BSpinner, BProgress, BProgressBar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useApollo from '@/plugins/graphql/useApollo'
import { quillEditor } from 'vue-quill-editor'
import ListFiles from './ListFiles.vue'
// import theme style
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css'
import ARCHITECTURE from '../useDownloads'

export default {
  components: {
    BFormFile,
    BCard,
    BButton,
    BFormInput,
    BFormSelect,
    ListFiles,
    BTab,
    BTabs,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    quillEditor,
    BProgress,
    BProgressBar,
  },
  data() {
    return {
      file: null,
      version: '',
      platform: 'WINDOWS',
      description: '',
      forceUpdate: false,
      platformList: [
        { text: 'Window', value: 'WINDOWS' },
        { text: 'Linux', value: 'LINUX' },
        { text: 'Mac Os', value: 'MAC' },
      ],
      architecture: 'X64',
      architectureList: ARCHITECTURE,
      required,
      isProcessing: false,
      updateKey: 'initial',
    }
  },
  methods: {
    uploadFile() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const input = {
            version: this.version,
            platform: this.platform,
            description: this.description ?? '',
            forceUpdate: this.forceUpdate,
            architecture: this.architecture,
          }
          useApollo.company.uploadApplication(input, this.file).then(() => {
            this.showSuccess('App Successfully Uploaded')
            this.updateKey = Math.random()
          }).catch(error => {
            this.showErrorMessage(error)
          })
            .finally(() => { this.isProcessing = false })
        }
      })
    },
  },
}
</script>
<style scoped>

  ::v-deep .ql-editor.ql-blank {
    min-height: 110px ;
  }
  ::v-deep .ql-editor {
    min-height: 110px ;
  }
</style>
